import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAOpQ-LlPN0Z6l8rc4bpRlxrP9gsHQdkr8",
  authDomain: "quant-suite.firebaseapp.com",
  databaseURL: "https://quant-suite-default-rtdb.firebaseio.com",
  projectId: "quant-suite",
  storageBucket: "quant-suite.appspot.com",
  messagingSenderId: "299006520018",
  appId: "1:299006520018:web:fda14d5b1502f9d5949b0b",
  measurementId: "G-R409MXNXQK",
};

// Check if there are no initialized apps
if (!getApps().length) {
  initializeApp(firebaseConfig);
}

const auth = getAuth();
const database = getDatabase();

export { auth, database };
