import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;

  .firebaseui-card {
    padding: 24px !important;
  }

  .firebaseui-input {
    width: 100% !important;
  }
`;

const Login = () => {
  const navigate = useNavigate();

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        navigate('/admin');
        return false;
      },
    },
  };

  return (
    <Wrapper>
      <h2>Login</h2>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </Wrapper>
  );
};

export default Login;