import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom";
import { Route } from "react-router";
import { BrowserRouter, Routes } from "react-router-dom";

// import "./index.css";
import App from "./App";
import Home from "./Home";
import Login from "./Login";
import Admin from "./Admin";
import AdminConsole from "./admin/AdminConsole";
import LoginAdmin from "./admin/Login";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App>
        <Routes>
          <Route path="/access_control" element={<Admin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/get_credential" element={<Login redirect={true} />} />
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<AdminConsole />} />
          <Route path="/admin/login" element={<LoginAdmin />} />
          <Route path="*" element={<div>Not found</div>} />
        </Routes>
      </App>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
