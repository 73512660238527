import React, { useState } from 'react';
import withAdminAuth from './withAdminAuth';
import UserEditor from './UserEditor';
import StreamEditor from './StreamEditor';
import styles from './AdminConsole.module.css';

const AdminConsole: React.FC = () => {
  const [activeTab, setActiveTab] = useState('userEditor');

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <div className={styles['admin-console']}>
      <h1 className="mt-2">Admin Console</h1>
      <div className={`card ${styles['card-custom']}`}>
        <div className={`card-header ${styles['header-custom']}`}>
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'userEditor' ? 'active' : ''}`}
                onClick={() => handleTabClick('userEditor')}
              >
                User Editor
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'streamEditor' ? 'active' : ''}`}
                onClick={() => handleTabClick('streamEditor')}
              >
                Stream Editor
              </button>
            </li>
            {/* Add more tabs for other components as needed */}
          </ul>
        </div>
        <div className={`card-body ${styles['body-custom']} ${styles['admin-console-content']}`}>
          {activeTab === 'userEditor' && <UserEditor />}
          {activeTab === 'streamEditor' && <StreamEditor />}
          {/* Add other components related to admin functionality here */}
        </div>
      </div>
    </div>
  );
};

export default withAdminAuth(AdminConsole);
