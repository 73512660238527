import './App.css';
import React from 'react';
// import ExternalLinkBar from './ExternalLinkBar';


const App = (props: any) => {
  return (
    <div className="App">
      {/* <ExternalLinkBar /> */}
      <header className="App-header">
        {props.children}
      </header>
    </div>
  );
}

export default App;
