// src/components/Admin/StreamEditor.tsx

import React, { useState, useEffect } from 'react';
import { ref, get, update, push, remove } from 'firebase/database';
import { database } from '../firebase';
import styles from './StreamEditor.module.css';
import Modal from './Modal';

interface StreamEditorProps {}

interface Stream {
  id: string;
  name: string;
  enabled: boolean;
}

const StreamEditor: React.FC<StreamEditorProps> = () => {
  const [streams, setStreams] = useState<Stream[]>([]);
  const [formState, setFormState] = useState<'add' | 'edit'>('add');
  const [selectedStreamId, setSelectedStreamId] = useState<string | null>(null);
  const [newStreamName, setNewStreamName] = useState<string>('');
  const [newStreamEnabled, setNewStreamEnabled] = useState<boolean>(false);

  // Fetch streams from RTDB
  const fetchStreams = async () => {
    const streamRef = ref(database, '/Streams');
    const snapshot = await get(streamRef);
    const data = snapshot.val();
    const streamsArray: Stream[] = [];

    for (const id in data) {
      streamsArray.push({ id, ...data[id] });
    }

    // Sort the streams alphabetically by the 'name' property
    streamsArray.sort((a, b) => a.name.localeCompare(b.name));

    setStreams(streamsArray);
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [streamToDelete, setStreamToDelete] = useState<Stream | null>(null);

  const handleDeleteButtonClick = (stream: Stream) => {
    setStreamToDelete(stream);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    if (streamToDelete) {
      removeStream(streamToDelete.id);
      setStreamToDelete(null);
    }
    setShowModal(false);
  };

  // Add a new stream
  const addStream = async (stream: Omit<Stream, 'id'>) => {
    const streamRef = ref(database, '/Streams');
    await push(streamRef, stream);
    fetchStreams();
  };

  // Update an existing stream
  const updateStream = async (id: string, stream: Omit<Stream, 'id'>) => {
    const streamRef = ref(database, `/Streams/${id}`);
    await update(streamRef, stream);
    fetchStreams();
  };

  // Remove a stream by ID
  const removeStream = async (id: string) => {
    const streamRef = ref(database, `/Streams/${id}`);
    await remove(streamRef);
    fetchStreams();
  };

  useEffect(() => {
    fetchStreams();
  }, []);

  // UI event handlers
  const onAddStream = () => {
    if (newStreamName.trim()) {
      const newStream: Omit<Stream, 'id'> = {
        name: newStreamName.trim(),
        enabled: newStreamEnabled
      };
      setNewStreamName('');
      setNewStreamEnabled(false);
      addStream(newStream);
    }
  };

  const onStartEditStream = (id: string, name: string, enabled: boolean) => {
    setFormState('edit');
    setSelectedStreamId(id);
    setNewStreamName(name);
    setNewStreamEnabled(enabled);
  };

  const onCancelEditStream = () => {
    setFormState('add');
    setSelectedStreamId(null);
    setNewStreamName('');
    setNewStreamEnabled(false);
  };

  const onUpdateStream = () => {
    if (selectedStreamId && newStreamName.trim()) {
      const updatedStream: Omit<Stream, 'id'> = {
        name: newStreamName.trim(),
        enabled: newStreamEnabled
      };
      updateStream(selectedStreamId, updatedStream);
      setFormState('add');
      setSelectedStreamId(null);
      setNewStreamName('');
      setNewStreamEnabled(false);
    }
  };

  return (
    <div className={`container ${styles.streamEditor} ${styles.widerContainer} mb-1`}>
      <div className="row">
        {/* <div className="col">
          <h2 className="my-4">Stream Editor</h2>
        </div> */}
      </div>

      {/* Stream Input */}
      <div className="row">
        <div className="col-md-7 d-flex align-items-center mb-2">
          <input
            type="text"
            className="form-control"
            id="streamNameInput"
            value={newStreamName}
            onChange={(e) => setNewStreamName(e.target.value)}
            placeholder="New stream name"
          />
        </div>

        <div className="col-md-3 d-flex align-items-center mb-2">
          {/* Wrapping label and input element inside custom-switch-container */}
          <div className="custom-switch-container">
            <div className="form-check form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchStatus"
                checked={newStreamEnabled}
                onChange={(e) => setNewStreamEnabled(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="customSwitchStatus">
                Enabled
              </label>
            </div>
          </div>
        </div>

        <div className="col-md-2 d-flex align-items-center justify-content-md-end mb-2">
          {formState === 'add' ? (
            <button className="btn btn-primary w-100" onClick={onAddStream}>
              <i className="fas fa-plus"></i>
            </button>
          ) : (
            <div className="d-flex">
              <button className="btn btn-primary btn-success" onClick={onUpdateStream}>
                <i className="fas fa-check"></i>
              </button>
              <div className="d-md-inline mx-1"></div>
              <button className="btn btn-primary btn-danger" onClick={onCancelEditStream}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Render the list of streams */}
      <ul className="list-group">
        {streams.map((stream) => (
          <li
            key={stream.id}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            <span>
              <span
                className="mr-3"
                style={{
                  display: 'inline-block',
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  backgroundColor: stream.enabled ? 'green' : 'red',
                  verticalAlign: 'middle',
                  marginRight: '1rem'
                }}
              ></span>
              {stream.name}
            </span>
            <div>
              <button
                className="btn btn-sm btn-info"
                onClick={() => onStartEditStream(stream.id, stream.name, stream.enabled)}
                style={{ marginRight: '0.5rem' }}
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleDeleteButtonClick(stream)}
              >
                <i className="fas fa-trash-can"></i>
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* Confirmation Modal */}
      <Modal
        show={showModal}
        title="Confirm Delete"
        body={
          <>
            Are you sure you want to delete{' '}
            <strong>{streamToDelete ? streamToDelete.name : ''}</strong> stream?
          </>
        }
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
};

export default StreamEditor;
