import styles from './UserEditor.module.css';
import React, { useState, useEffect } from 'react';
import { ref, get, update, remove } from 'firebase/database';
import { database } from '../firebase';
import Modal from './Modal';

interface UserEditorProps {}

interface User {
  id: string;
  name: string;
  admin: boolean;
  streamAdmin: boolean;
  blocked: boolean;
  readStreams: Record<string, boolean>;
  writeStreams: Record<string, boolean>;
}

interface Stream {
  id: string;
  name: string;
}

const UserEditor: React.FC<UserEditorProps> = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [newUserName, setNewUserName] = useState<string>('');
  const [newUserAdmin, setNewUserAdmin] = useState<boolean>(false);
  const [newUserStreamAdmin, setNewUserStreamAdmin] = useState<boolean>(false);
  const [newUserBlocked, setNewUserBlocked] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [streams, setStreams] = useState<Stream[]>([]);

  // Map selected user's readStreams and writeStreams value to a dictionary (Record<string, boolean>)
  const [readStreamIds, setReadStreamIds] = useState<Record<string, boolean>>({});
  const [writeStreamIds, setWriteStreamIds] = useState<Record<string, boolean>>({});

  // Add a state to manage the search query.
  const [searchQuery, setSearchQuery] = useState<string>('');

  // Filter the streams based on the search query.
  const filteredStreams = streams.filter((stream) =>
    stream.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Fetch streams from RTDB
  const fetchStreams = async () => {
    const streamsRef = ref(database, '/Streams');
    const snapshot = await get(streamsRef);
    const data = snapshot.val();
    const streamsArray: Stream[] = [];

    for (const id in data) {
      const name = data[id].name;
      streamsArray.push({ id, name });
    }
    // Sort the streams alphabetically by the 'name' property
    streamsArray.sort((a, b) => a.name.localeCompare(b.name));

    setStreams(streamsArray);
  };

  // Fetch users from RTDB
  const fetchUsers = async () => {
    const usersRef = ref(database, '/user_permissions');
    const snapshot = await get(usersRef);
    const data = snapshot.val();
    const usersArray: User[] = [];

    for (const id in data) {
      const name = data[id].name || '<Not Set>'; // Set a default empty name if not provided
      const admin = data[id].admin || false; // Set a default value for admin
      const streamAdmin = data[id].streamAdmin || false; // Set a default value for streamAdmin
      const blocked = data[id].blocked || false; // Set a default value for blocked
      usersArray.push({ id, name, admin, streamAdmin, blocked, ...data[id] });
    }

    // Sort the users alphabetically by the 'name' property
    usersArray.sort((a, b) => a.name.localeCompare(b.name));
    setUsers(usersArray);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      removeUser(userToDelete.id);
      setShowModal(false);
      setUserToDelete(null);
    }
  };

  // Update an existing user
  const updateUser = async (id: string, user: Omit<User, 'id'>) => {
    // Remove any properties with undefined values
    const filteredUser = Object.entries(user).reduce(
      (acc, [key, value]) => (value === undefined ? acc : { ...acc, [key]: value }),
      {}
    );

    const userRef = ref(database, `/user_permissions/${id}`);
    await update(userRef, filteredUser);
    fetchUsers();
  };

  // Remove a user by ID
  const removeUser = async (id: string) => {
    const userRef = ref(database, `/user_permissions/${id}`);
    await remove(userRef);
    fetchUsers();
  };

  useEffect(() => {
    fetchUsers();
    // fetchStreams(); // Fetch streams on component mount
  }, []);

  // UI event handlers
  const onStartEditUser = (user: User) => {
    fetchStreams().then(() => {
      setSelectedUserId(user.id);
      setNewUserName(user.name);
      setNewUserAdmin(user.admin);
      setNewUserStreamAdmin(user.streamAdmin);
      setNewUserBlocked(user.blocked);
      // Set readStreams and writeStreams
      setReadStreamIds(user.readStreams || {}); // Set an empty object if not provided
      setWriteStreamIds(user.writeStreams || {}); // Set an empty object if not provided
    });
  };

  const onCancelEditUser = () => {
    setSelectedUserId(null);
    setNewUserName('');
    setNewUserAdmin(false);
    setNewUserStreamAdmin(false);
    setNewUserBlocked(false);
  };

  const onUpdateUser = () => {
    if (selectedUserId) {
      const updatedUser: Omit<User, 'id'> = {
        name: newUserName.trim(),
        admin: newUserAdmin,
        streamAdmin: newUserStreamAdmin,
        blocked: newUserBlocked,
        readStreams: readStreamIds,
        writeStreams: writeStreamIds
      };

      updateUser(selectedUserId, updatedUser);
      onCancelEditUser();
    }
  };

  return (
    <div className={`container ${styles.userEditor}`}>
      <div className="row">
        {/* <div className="col">
          <h2 className="my-4">User Editor</h2>
        </div> */}
      </div>

      {/* Render the list of users */}
      <ul className="list-group">
        {users.map((user) => (
          <li
            key={user.id}
            className={`list-group-item d-flex align-items-center ${
              user.blocked ? styles.blockedUser : ''
            }`}
          >
            <div className={`${styles.iconContainer} me-2`}>
              {/* Show different icons based on the admin status and blocked status */}
              {user.blocked ? (
                <i className="fas fa-user-slash"></i>
              ) : user.admin ? (
                <i className="fas fa-user-shield"></i>
              ) : (
                <i className="fas fa-user"></i>
              )}
            </div>
            {user.name}
            <div className="ms-auto">
              <button
                className="btn btn-sm btn-info"
                onClick={() => onStartEditUser(user)}
                style={{ marginRight: '0.5rem' }}
              >
                <i className="fas fa-pencil"></i>
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => {
                  setUserToDelete(user);
                  setShowModal(true);
                }}
              >
                {/* <i class="fa-sharp fa-solid fa-user-minus"></i> */}
                <i className="fas fa-user-minus"></i>
              </button>
            </div>
          </li>
        ))}
      </ul>

      {selectedUserId && (
        <div className="mt-4">
          <h3>Edit user</h3>
          <div className="input-group mb-3">
            <span className="input-group-text">User name</span>
            <input
              type="text"
              className="form-control"
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value)}
              placeholder="User name"
            />
          </div>
          <div className="form-check form-check-inline">
            <input
              id="newUserAdmin"
              type="checkbox"
              className="form-check-input"
              checked={newUserAdmin}
              onChange={(e) => setNewUserAdmin(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="newUserAdmin">
              Admin
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              id="newUserStreamAdmin"
              type="checkbox"
              className="form-check-input"
              checked={newUserStreamAdmin}
              onChange={(e) => setNewUserStreamAdmin(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="newUserStreamAdmin">
              Stream Admin
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              id="newUserBlocked"
              type="checkbox"
              className="form-check-input"
              checked={newUserBlocked}
              onChange={(e) => setNewUserBlocked(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="newUserBlocked">
              Blocked
            </label>
          </div>
          {/* Add a search input field */}
          <div className="mt-4">
            <div className="input-group mb-3">
              <span className="input-group-text">Filter Streams</span>
              <input
                type="text"
                className="form-control"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by stream name"
              />
            </div>
          </div>
          {/* Render readStreams and writeStreams list management components here */}
          <div className="d-flex">
            {/* Read Streams */}
            <div className="mt-1 flex-grow-1" style={{ marginRight: '3rem' }}>
              <h5>Read Streams:</h5>
              <div className={`${styles.streamContainer}`}>
                {filteredStreams.map((stream) => (
                  <div className="form-check col-12" key={stream.id}>
                    <input
                      id={`readStream-${stream.id}`}
                      type="checkbox"
                      className="form-check-input"
                      checked={readStreamIds[stream.id] || false}
                      onChange={(e) => {
                        const newDict = { ...readStreamIds };
                        e.target.checked ? (newDict[stream.id] = true) : delete newDict[stream.id];
                        setReadStreamIds(newDict);
                      }}
                    />
                    <label
                      className={`form-check-label ${styles.streamLabel}`}
                      htmlFor={`readStream-${stream.id}`}
                    >
                      {stream.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/* Write Streams */}
            <div className="mt-1 flex-grow-1">
              <h5>Write Streams:</h5>
              <div className={`${styles.streamContainer}`}>
                {filteredStreams.map((stream) => (
                  <div className="form-check col-12" key={stream.id}>
                    <input
                      id={`writeStream-${stream.id}`}
                      type="checkbox"
                      className="form-check-input"
                      checked={writeStreamIds[stream.id] || false}
                      onChange={(e) => {
                        const newDict = { ...writeStreamIds };
                        e.target.checked ? (newDict[stream.id] = true) : delete newDict[stream.id];
                        setWriteStreamIds(newDict);
                      }}
                    />
                    <label
                      className={`form-check-label ${styles.streamLabel}`}
                      htmlFor={`writeStream-${stream.id}`}
                    >
                      {stream.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Save and Cancel buttons */}
          <div className="mt-4">
            <button className="btn btn-success me-2" onClick={onUpdateUser}>
              <i className="fas fa-check"></i> Save
            </button>
            <button className="btn btn-danger" onClick={onCancelEditUser}>
              <i className="fas fa-xmark"></i> Cancel
            </button>
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      <Modal
        show={showModal}
        title="Confirm Delete"
        body={
          <>
            Are you sure you want to delete <strong>{userToDelete ? userToDelete.name : ''}</strong>{' '}
            user?
          </>
        }
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
};

export default UserEditor;
