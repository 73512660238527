import styled from 'styled-components';

export const LinkButton = styled.a`
width: 150px;
background: #33DFF2;
margin:5px;
padding:5px;
text-decoration:none;
color:#282c34;
border-radius: 5px;
font-weight:bold;
text-transform:uppercase;
border: 3px solid;
border-color:transparent;
font-size: calc(10px + 1vmin);
  transition: all 2.75s ease;
&:hover{
  background: #971ADA;
  color:white
}
`
// const links = [
//     { url: "https://example.com/", title: "Join Waitlist" }
// ]
const Home = (props: any) => {
    const logo = require("./logo.png")
    return (<>
        <img src={logo} className="App-logo" alt="Quantsuite" style={{ maxWidth: "50%" }} />
        {
            // links.map(link => {
            //     return (
            //         <LinkButton href={link.url}>{link.title}</LinkButton>
            //     )
            // })
        }
    </>
    );
}

export default Home;
