import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../firebase';
import type { User } from "firebase/auth";
import { ref, get, DataSnapshot } from "firebase/database";

interface State {
  isAdmin: boolean;
  isChecking: boolean;
}

const withAdminAuth = (Component: React.FunctionComponent) => {
  return function WithAdminAuth(props: any) {
    const [state, setState] = useState<State>({ isAdmin: false, isChecking: true });
    const navigate = useNavigate();

    useEffect(() => {
      return auth.onAuthStateChanged((user: User | null) => {
        if (user) {
          const userRef = ref(database, `/user_permissions/${user.uid}`);
          get(userRef).then((snapshot: DataSnapshot) => {
            if (snapshot.val()?.admin) {
              setState({ isAdmin: true, isChecking: false });
            } else {
              setState({ isAdmin: false, isChecking: false });
              //alert("You are not an admin.");
              navigate("/admin/Login");
            }
          });
        } else {
          //alert("You are not logged in.");
          setState({ isAdmin: false, isChecking: false });
          navigate("/admin/Login");
        }
      });
    }, [navigate]);

    const { isAdmin, isChecking } = state;

    return (
      <>
        {isChecking ? (
          // Show a loader while checking for user permissions.
          <div>Loading...</div>
        ) : isAdmin ? (
          // Render the admin component if the user is an admin
          <Component {...props} />
        ) : null}
      </>
    );
  };
};

export default withAdminAuth;