import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { LinkButton } from './Home';


// // Configure Firebase.
// const config = {
//     apiKey: 'AIzaSyAOpQ-LlPN0Z6l8rc4bpRlxrP9gsHQdkr8',
//     authDomain: 'quant-suite.firebaseapp.com',
//     // ...
// };


// firebase.initializeApp(config);

const Login = (props: any) => {

    const uiConfig = {
        signInFlow: 'redirect',
        callbacks: {
            signInSuccessWithAuthResult: (e: any) => {
                redirectAfterLogin();
                return false
            },
        },
        // We will display Google and email as auth providers.
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
    };

    const redirectAfterLogin = () => {

        const user = firebase.auth().currentUser;
        if (!user) return;
        user.getIdTokenResult().then((tokenResult) => {
            //Make sure user is in DB
            fetch('/api/register', { headers: { authorization: tokenResult.token } });

            //Send data to client if page calls for it
            if (props.redirect) {
                const refreshToken = (user as any)._delegate.stsTokenManager.refreshToken;
                window.location.href = `http://localhost:9001/authRedirect?uid=${tokenResult.claims["user_id"]}&refreshToken=${refreshToken}&idToken=${tokenResult.token}`;
            }
        });
    }

    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    useEffect(() => {
        if (isSignedIn) {
            redirectAfterLogin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn])

    if (!isSignedIn) {
        return (
            <div>
                <p>Please sign-in:</p>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>
        );
    }
    return (
        <div>
            <p>You are signed in as {firebase.auth().currentUser!.displayName}</p>
            <LinkButton onClick={() => firebase.auth().signOut()}>Sign-out</LinkButton>
        </div>
    );
}

export default Login;
