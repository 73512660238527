import { useEffect, useState } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/compat/app';
import Login from './Login';

export const TextInput = styled.input`
width: 350px;
background: #33DFF2;
margin:5px;
padding:5px;
text-decoration:none;
color:#282c34;
border-radius: 5px;
font-weight:bold;
text-transform:uppercase;
border: 3px solid;
border-color:transparent;
font-size: calc(10px + 1vmin);
  transition: all 2.75s ease;
&:hover{
  background: #971ADA;
  color:white
}
`
export const Button = styled.button`
width: 150px;
background: #33DFF2;
margin:5px;
padding:5px;
text-decoration:none;
color:#282c34;
border-radius: 5px;
font-weight:bold;
text-transform:uppercase;
border: 3px solid;
border-color:transparent;
font-size: calc(10px + 1vmin);
`

const Admin = (props: any) => {
  const [email, setEmail] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver();
  }, []);

  const updateAccess = () => {
    const user = firebase.auth().currentUser;
    user?.getIdToken().then(token => {
      fetch(`/api/grantAccess/?email=${email}`, {
        headers: { authorization: token }
      }).then(result => result.text())
        .then(text => alert(text))
    });
  };

  return (<>
    {!isSignedIn && <Login />}
    {isSignedIn && <>
      <TextInput placeholder='User Email' value={email}
        onChange={e => setEmail(e.target.value)} />
      <Button onClick={updateAccess}>Register</Button ></>
    }
  </>
  );
}

export default Admin;
